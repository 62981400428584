import * as React from "react";
import Redirect from "../../../components/redirect";
//--------------------------------------------------

  const url = "https://app.instagantt.com/shared/s/zgwliud9bKVuqdCB0w6j/latest";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
